export default function SplashScreen() {
    return (
        <div
            className="fixed top-0 left-0 z-50 w-screen h-screen bg-white bg-opacity-50 flex items-center justify-center"
            style={{ backdropFilter: 'blur(5px)' }}
        >
            <span className="flex h-20 w-20 animate-pulse items-center justify-center">
                <Spinner />
            </span>
        </div>
    );
}

export const Spinner = () => (
    <span className="w-12 h-12 border-4 border-[#da2036] border-b-transparent rounded-full inline-block box-border animate-spin"></span>
);
